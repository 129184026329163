import React from "react"
import "./FooterLinks.scss"

const _ = require("lodash")

const footerData = [
  {
    name: "Tech Stack",
    items: [
      "HTML",
      "CSS (SCSS, LESS)",
			"Vanilla Javascript",
			"Pyhton",
			"OCaml",
      "React (JS)",
      "D3 (JS)",
      "Hugo (Go)",
      "Gatsby (React, JS)",
      "Angular (JS)",
      "Wordpress (PHP)",
      "Wordpress Blocks (PHP, React)",
			"Build Tools (Gulp, Webpack)",
    ],
    links: []
  },
  {
    name: "Skill Stack",
    items: [
      "Design",
			"Wireframing",
			"Object oriented programming",
			"Functional programming",
      "Prototyping",
      "Interaction design",
      "UI Design",
      "UX Design",
      "Web animations",
      "Accessibility",
      "Optimization",
      "Usability testing",
      "A/B testing",
    ],
    links: []
  },
  {
    name: "Web Service Stack",
    items: [
      "Netlify",
      "Bitbucket",
      "Github",
      "Cloudflare",
      "Jira",
      "Google Analytics",
      "Google Optimize",
    ],
    links: []
  },
]

function FooterColumn(props) {
  const {name, items, links} = props
  return(
    <div className="footer__column">
      <h3>{name}</h3>
      <ul 
        className="footer__column__list"
        style={{
          listStyle: 'none',
          padding: '0',
          fontSize: 'var(--scale--sub1)',
          lineHeight: '2',
        }}
      >
        {items.map( (item) => ( <li key={`${_.kebabCase(item)}`}>{item}</li> ) )}
        {links.map( (link) => ( 
          <li key={`${_.kebabCase(link.name)}`}>
            <a 
              href={link.dest}
              style={{
                color: 'currentColor',
                textDecoration: 'none',
                display: 'inline-block',
              }}
            >
              {link.name}
            </a>
          </li> 
        ))}
      </ul>
    </div>
  )
}

function FooterLinks(props) {
  const { config } = props
  const { copyright } = config
  if (!copyright) {
    return null
  }

  return(
    <footer
      id="footer__links"
      className="inverse container grid grid--outer grid__padding"
      aria-label="site footer"
      style={{backgroundColor: "var(--color--bg)"}}
    >
      {footerData.map( (column, i) => (
        <FooterColumn key={i} name={column.name} items={column.items} links={column.links} />
        )
      )}

      <div
        className="copyright"
        style={{ 
					textAlign: "right", 
					marginTop: "var(--spacing--2)",
					gridColumn: "start / end" 
				}}
      >
        <small>{copyright}</small>
      </div>
    </footer>
  )
}

export default FooterLinks
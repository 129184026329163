import React from "react"
import FooterBio from '../FooterBio/FooterBio'
import FooterLinks from '../FooterLinks/FooterLinks'

function Footer(props) {
  const { config } = props
  const { copyright } = config
  if (!copyright) {
    return null
  }
  return (
    <>
      <FooterBio config={config} />
      <FooterLinks config={config} copyright={copyright} />
    </>
  )
}

export default Footer

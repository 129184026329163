import axios from "axios";
import React, { useState } from "react";

function ContactForm() {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://formspree.io/xvoqbrbq",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <form onSubmit={handleOnSubmit}>
      <label htmlFor="email">Your Email:</label>
      <input id="email" type="email" name="email" autoFocus required />
      <label htmlFor="message">Your Message:</label>
      <textarea id="message" name="message" rows="4"></textarea>
      <button type="submit" disabled={serverState.submitting}>
        Submit
      </button>
      {serverState.status && (
        <p className={!serverState.status.ok ? "errorMsg" : ""}>
          {serverState.status.msg}
        </p>
      )}
    </form>
  );
}

export default ContactForm;

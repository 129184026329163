import React from "react"
import IconInline from '../IconInline/IconInline'


function UserLinks(props) {
  const { config } = props
  const { userLinks } = config
  
  function getLinkElements() {
    return userLinks.map(link => (
      <a 
        key={link.label} 
        href={link.url} 
        style={{ marginRight: "2em" }} 
        aria-label={`${link.label}`}
      >
        <IconInline
          name={link.iconClassName}
          style={{ 
						marginRight: "0.4ch", 
						fontSize: 'var(--scale--2)',
						height: 'var(--scale--2)',
						width: 'var(--scale--2)',
						fill: 'var(--color--text)'
					 }}
          width={18}
          height={18}
        />
        {link.text}
      </a>
    ))
  }

  if (!userLinks) {
    return null
  }
  return (
    <div className="user-links">{getLinkElements()}</div>
  )
  
}

export default UserLinks

import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import UserLinks from '../UserLinks/UserLinks'
import "./FooterBio.scss"

function FooterBio(props) {
  const { config } = props
  return (
    <div
      id="footer__bio"
      className="container grid grid--outer grid__padding"
      aria-label="site footer"
      style={{backgroundColor: 'var(--color--body'}}
    >
      <hr />

      <StaticQuery
        query={graphql`
          query {
            profileImage: file(relativePath: { eq: "assets/theme/profile-jeff_szuc-clear.png" }) {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED,
                  quality: 90,
                  width: 344,
                  placeholder: BLURRED
                )
              }
            }
          }
        `}
        render={data => (
          <GatsbyImage
            className="footer__bio__profile-img"
            image={data.profileImage.childImageSharp.gatsbyImageData}
            alt="Jeff Szuc"
            width={344}
            height={344}
            backgroundColor="var(--color--bg--light)"
            style={{
              borderRadius: "100%",
              width: "100%"
            }}
          />
        )}
      />

      <div className="footer__bio__content" style={{ paddingLeft: "var(--spacing--1)" }}>
        <h2 id="section-heading--contact" className="homepage__section-heading" style={{marginTop:"0"}}>
          {config.userName}
          <br />
          {config.userTitle}
        </h2>
        <p>{config.userDescription}</p>

        <UserLinks config={config} labeled />
      </div>
      <hr />
    </div>
  )
}

export default FooterBio
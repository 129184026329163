import React from "react"
import Modal from "react-modal"
import ContactForm from '../ContactForm/ContactForm'
import IconInline from '../IconInline/IconInline'
import "./ContactModal.scss"

const modalBoxStyles = {
  content: {
    top: 0,
    left: "auto",
    right: 0,
    bottom: 0,
    scrollY: "auto",
    padding: "var(--spacing--2)",
    paddingRight: "var(--spacing--3)",
    backgroundColor: "var(--color--body)",
  }
}

Modal.setAppElement("#___gatsby");
Modal.defaultStyles.overlay.background =
  "linear-gradient(30deg, var(--item--modal-gradient--dark), var(--item--modal-gradient--light) 80%)";
Modal.defaultStyles.content.border = "none";
Modal.defaultStyles.content.borderRadius = "8px 0 0 8px";

function ContactModal() {
  const [modalIsOpen,setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal(){
    setIsOpen(false);
  }

  return (
    <>
      <button type="button" onClick={openModal}>Contact</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalBoxStyles}
        contentLabel="Contact form"
      >
        <ContactForm />
        <button
          type="button"
          className="modal__close"
          onClick={closeModal}
          aria-label="Close Modal"
        >
          <IconInline name="close" width={28} height={28} style={{"fill": "var(--color--text)"}} />
        </button>
      </Modal>
    </>
  )
}

export default ContactModal

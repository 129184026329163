import { Link } from "gatsby"
import React, { useState } from "react"
import useScrollPosition from '../../hooks/useScrollPosition'
import ContactModal from '../ContactModal/ContactModal'
import IconInline from '../IconInline/IconInline'
import "./Navbar.scss"

function Navbar() {
  const [expanded, setExpanded] = useState({navExpanded: true})
    
  function handleScroll({ prevPos, currPos }) {
    const newY = currPos.y
    const oldY = prevPos.y
    if (newY <= 0 || newY < oldY) {
      setExpanded({navExpanded: true})
    } 
    else if (expanded.navExpanded) {
      setExpanded({navExpanded: false})
    }
  }

  useScrollPosition(handleScroll, [expanded], null, true, 300)
  
  return (
    <div
      className={expanded.navExpanded ? "navbar" : "navbar navbar--collapsed"}
      role="banner"
      aria-label="primary site navigation"
    >
      <button
        id="nav-toggle"
        className="navbar__toggle"
        onClick={() => setExpanded({navExpanded: !expanded.navExpanded})}
        tabIndex={0}
        aria-label="navigation visibility toggle"
        aria-haspopup="true"
        aria-expanded={expanded.navExpanded}
        type="button"
      >
        <IconInline 
          name="plus" 
          width={28} 
          height={28} 
          style={{
						height: "var(--spacing--sub1)", 
						width: "var(--spacing--sub1)",
						fill: "var(--color--text--inverse)"
					}}
        />
      </button>

      <nav
        className="navbar__nav"
        aria-label="primary site navigation"
        aria-labelledby="nav-toggle"
      >
        <Link to="/" activeClassName="nav--active">Home</Link>
        <Link to="/articles" activeClassName="nav--active">Articles</Link>
        <ContactModal />
      </nav>
    </div>
  )
}

export default Navbar

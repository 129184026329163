import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import config from "../../../data/SiteConfig"
import favicon128 from "../../../static/logos/logo_128.png"
import favicon32 from "../../../static/logos/logo_32.png"
import Navbar from '../Navbar/Navbar'
import ThemeToggle from '../ThemeToggle/ThemeToggle'
import useEvent from '../../hooks/useEvent'
import "./Layout.scss"

export default function Layout({children}) {
  const [tabbing, setTabbing] = useState({userTabbing: false})
  const [lightMode, setLightMode] = useState('default')

  const toggleTheme = () => {
    const localTheme = window.localStorage.getItem('lightMode')
    const savedMode = localTheme === 'inverted' ? 'inverted' : 'default'
    if (savedMode === 'default') {
      window.localStorage.setItem('lightMode', 'inverted')
      setLightMode('inverted')
    } else {
      window.localStorage.setItem('lightMode', 'default')
      setLightMode('default')
    }
  }

  useEffect(() => {
    const localTheme = window.localStorage.getItem('lightMode')
    setLightMode(localTheme)
    if (localTheme === 'inverted') {
      document.body.classList.add('is_inverted')
    }
    else {
      document.body.classList.remove('is_inverted')
    }
  },[lightMode])

  // if key is 'tab' then stop listening for keys and listen for mouse events
  function handleFirstTab(event) {
    if (event.keyCode === 9) {
      setTabbing({userTabbing: true})
    }
  }

  // if a mouse event is detected, then stop listening for clicks and start listening for keys
  function handleMouseDownOnce() {
    setTabbing({userTabbing: false})
  }

  useEvent("mousedown", handleMouseDownOnce)
  useEvent("keydown", handleFirstTab)

  return (
    <div className={`layout ${tabbing.userTabbing ? 'user-is-tabbing' : ''} `}>
      <Helmet
        link={[
          { rel: 'icon', type: 'image/png', sizes: "32x32", href: `${favicon32}` },
          { rel: 'icon', type: 'image/png', sizes: "128x128", href: `${favicon128}` },
        ]}
      >
        <meta name="description" content={config.siteDescription} />
        <html lang="en" />
      </Helmet>
      <a 
        id="skip__link" 
        href="#skip__target"
        style={{
          color: 'var(--color--text--inverse)',
          backgroundColor: 'var(--color--bg)',
          padding: 'var(--spacing--sub2) var(--spacing--sub1)',
          lineHeight: '1',
          zIndex: '999',
          position: 'fixed',
          left: '0.8em',
        }}
      >
        Skip to content
      </a>
      <Navbar />
      <ThemeToggle themeSwitch={toggleTheme} />
      {children}
    </div>
  )
}

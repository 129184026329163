import React from "react";
import Circle from "../../../static/assets/icons/circle.inline.svg";
import Close from "../../../static/assets/icons/close.inline.svg";
import Comment from "../../../static/assets/icons/comment.inline.svg";
import External from "../../../static/assets/icons/external.inline.svg";
import Plus from "../../../static/assets/icons/plus.inline.svg";
import Linkedin from "../../../static/assets/icons/social-linkedin.inline.svg";
import Twitter from "../../../static/assets/icons/social-twitter.inline.svg";
import Github from "../../../static/assets/icons/social-github.inline.svg";
import Square from "../../../static/assets/icons/square.inline.svg";
import Triangle from "../../../static/assets/icons/triangle.inline.svg";

function IconInline(props) {
  const { name, width, height, style } = props;
  const defaultStyle = {
    height: "1em",
    verticalAlign: "middle",
    transform: "translateY(-0.125em)",
    fill: 'currentcolor',
  }
  const iconStyle = {
    ...defaultStyle,
    ...style,
  }

  return (
    <>
      {name === 'triangle' ? <Triangle style={iconStyle} height={height} width={width} /> : ''}
      {name === 'circle' ? <Circle style={iconStyle} height={height} width={width} /> : ''}
      {name === 'square' ? <Square style={iconStyle} height={height} width={width} /> : ''}
      {name === 'close' ? <Close style={iconStyle} height={height} width={width} /> : ''}
      {name === 'plus' ? <Plus style={iconStyle} height={height} width={width} /> : ''}
      {name === 'external' ? <External style={iconStyle} height={height} width={width} /> : ''}
      {name === 'twitter' ? <Twitter style={iconStyle} height={height} width={width} /> : ''}
      {name === 'linkedin' ? <Linkedin style={iconStyle} height={height} width={width} /> : ''}
      {name === 'github' ? <Github style={iconStyle} height={height} width={width} /> : ''}
      {name === 'comment' ? <Comment style={iconStyle} height={height} width={width} /> : ''}
    </>
  );
}

export default IconInline;

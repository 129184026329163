// @https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
import { useRef, useLayoutEffect } from 'react'

// Check if is in browser (ie. not server-side rendering)
const isBrowser = typeof window !== `undefined`

/**
 * returns object with x and y scroll or position values
 * @param {object} element - useRef element or Null for element or body
 * @param {boolean} useWindow - return window scroll or element position
 * @returns {object} x and y values
 */
function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

/**
 * 
 * @param {function} effect - effect to call on scroll
 * @param {array} deps - state objects which should trigger re-render
 * @param {object} element - useRef element or Null for element or body
 * @param {boolean} useWindow - return window scroll or element position
 * @param {number} wait - Number of milliseconds to throttle scroll watching by 
 */
export default function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}
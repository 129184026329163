const now = new Date();
const year = now.getFullYear();
const config = {
  siteTitle: `Jeff Szuc`, // Site title.
  siteTitleShort: "Jeff Szuc", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: `${year} Full-Stack Design`, // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://jeffszuc.com", // Domain of your website without pathPrefix.
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    "Digital Product Designer/Developer—UX Design, Front-end Development, UI Design, Javascript, Frameworks, CSS, PHP, Build Tools, Project Management, Accessibility, A/B testing, Usability Testing, Analytics", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "Jeff Szuc RSS",
  siteFBAppID: "114112468076", // FB Application ID for using app insights
  googleAnalyticsID: "UA-8654578-1", // google UA tracking ID.
  googleAnalyticsID4: "G-T5H9TY62EG", // google GA4 tracking ID.
  googleOptimizeID: "OPT-WMRR3WV", // google optimize container ID.
  disqusShortname: "jeffszuc", // Disqus shortname.
  postDefaultCategoryID: "Development", // Default category for posts.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "MM/DD/YYYY", // Date format for display.
  postsPerPage: 100, // Amount of posts displayed per listing page.
  userName: "Jeff Szuc", // Username to display in the author segment.
  userTitle: "Product Designer",
  userEmail: "jeffszuc@gmail.com", // Email used for RSS feed's author segment
  userTwitter: "szuc", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Ithaca, NY", // User location to display in the author segment.
  userAvatar: "/assets/theme/profile-jeff_szuc.jpg", // User avatar to display in the author segment.
  userDescription:
    "Jeff Szuc is a UX Designer and Web Developer. Skills include UI Design, User-centered Design Methods, Prototyping, Javascript Frameworks, CSS, PHP, Build Tools, Project Management, Accessibility, Usability testing, A/B testing, Analytics, User Interviews. If you need help on a project or another member on your dev team, I am always looking for exciting projects to work on.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "LinkedIn",
      text: "jeffszuc",
      url: "https://www.linkedin.com/in/jeffszuc",
      iconClassName: "linkedin",
    },
    {
      label: "Twitter",
      text: "@szuc",
      url: "https://twitter.com/szuc",
      iconClassName: "twitter",
    },
    {
      label: "Github",
      text: "szuc",
      url: "https://github.com/szuc",
      iconClassName: "github",
    },
  ],
  copyright: `Copyright ©2005–${year} Jeff Szuc`, // Copyright string for the footer of the website and RSS feed.
  themeColor: "#333333", // Used for setting manifest and progress theme colors.
  backgroundColor: "#333333", // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;

import React from "react"
import "./ThemeToggle.scss"

export default function ThemeToggle({ themeSwitch = f => f }) {
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button 
      className="themeToggle"
      type="button"
      onClick={themeSwitch}
      aria-label="toggle theme color"
    />
  )
}